﻿var $ = require("../lib/jquery");

$.addTimeToQuery = function(query) {
	var time = "_=" + $.now();
	if (query.indexOf("?_=") >= 0) {
		return query.replace(/\?_=[0-9]+/, "?" + time);
	} else if (query.indexOf("&_=") >= 0) {
		return query.replace(/&_=[0-9]+/, "&" + time);
	} else if (query.indexOf("?") >= 0) {
		return query + "&" + time;
	} else {
		return query + "?" + time;
	}
};

$.fn.extend({
	setVisibleAndClearValue: function(visible) {
		this.each(function() {
			var item = $(this);
			if (visible) {
				if (item.is(":not(:visible)")) {
					item.find(":text,:password").each(function() {
						var input = $(this);
						input.val(input.data("___oldvalue"));
					});
				}
				item.show();
			} else {
				if (item.is(":visible")) {
					item.find(":text,:password").each(function() {
						var input = $(this);
						input.data("___oldvalue", input.val());
						input.val("");
					});
				}
				item.hide();
			}
		});
	}
});