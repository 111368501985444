﻿/*$(function() {
    $('a[class*=thickbox]').thickbox();
});*/

var $ = require("./lib/jquery");

$(document).ready(function() {

    $('a.ext').click(function() {
        window.open(this.href);
        return false;
    });
  
    var hasPlaceholderSupport = ('placeholder' in document.createElement('input'));

    function clearPlaceholder() {
        var input = $(this);
        if (input.val() == input.attr('placeholder')) {
            input.val('');
        }
    }

    function setPlaceHolder() {
        var input = $(this);

        if (input.attr("type") == "password")
            return;

        if (input.val() == '' || input.val() == input.attr('placeholder')) {
            input.val(input.attr('placeholder'));
        }
    }


    if (!hasPlaceholderSupport) {
        $('input[placeholder], textarea[placeholder]').each(function() {
            var self = $(this);

            if (self.attr("type") == "password")
                return;

            self.val(self.attr('placeholder'));

            self.focus(clearPlaceholder);
            self.blur(setPlaceHolder);
        }); 
        $("form").submit(function() {
            $("input[placeholder],textarea[placeholder]", this).each(clearPlaceholder);
        });
    }

	$.validator.unobtrusive.parse(document);

    if (!hasPlaceholderSupport) {
        $("form").submit(function(event) {
            if (event.isDefaultPrevented()) {
                $("input[placeholder],textarea[placeholder]", this).each(setPlaceHolder);
            }
        });
    }

});