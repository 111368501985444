﻿var $ = require("../lib/jquery");

var accountWeights = [6, 3, 7, 9, 10, 5, 8, 4, 2, 1];
var icoWeights = [8, 7, 6, 5, 4, 3, 2];


function escapeAttributeValue(value) {
	// As mentioned on http://api.jquery.com/category/selectors/
	if (!value)
		return value;

	return value.replace(/([!"#$%&'()*+,./:;<=>?@\[\\\]^`{|}~])/g, "\\$1");
}

function getModelPrefix(fieldName) {
	if (!fieldName)
		return fieldName;

	return fieldName.substr(0, fieldName.lastIndexOf(".") + 1);
}

function appendModelPrefix(value, prefix) {
	if (value && value.indexOf("*.") === 0) {
		value = value.replace("*.", prefix);
	}
	return value;
}

function isValidDate(year, month, day) {
	var date = new Date();
	date.setTime(0);
	date.setDate(1);
	date.setFullYear(year);
	date.setMonth(month - 1);
	date.setDate(day);

	return date.getDate() == day && date.getMonth() == (month - 1) && date.getFullYear() == year
}

// compute Mod 11 weighted sum

function computeMod11WeightedSum(value, weights) {
	var number, i, j;

	if (value == null || weights == null || typeof(value) !== "string")
		throw 'value or weights are empty';

	if (value.length > weights.length)
		throw "Value is longer than weights";
	// number            
	number = 0;
	// index and weight index
	i = value.length, j = weights.length;

	var x = "";
	// compute weighted sum
	while (i > 0) {
		--i;
		number = (number + parseInt(value.substring(i, i + 1), 10) * weights[--j]) % 11;
	}
	// return number
	return number;
};

function isValidAccountNumber(account, regexOnly) {
	var match, parts, result, i;
	// test account
	if (!account || typeof(account) !== "string")
		return false;

	// test, if account matches regex
	match = account.match(/^(|[0-9]{1,6}\-)([0-9]{2,10})$/);
	if (!match)
		return false;

	if (match && regexOnly)
		return true;

	parts = account.split(/-/);

	result = true;
	for (i = 0; i < parts.length; i++)
		result = result && (computeMod11WeightedSum(parts[i], accountWeights) == 0);
	return result;
}

function isValidPersonalNumber(data, withSlash, regexOnly) {
	var match, year, month, day, check, valid, fullYear;
	// check personal number regex
	match = data.match(new RegExp("^([0-9]{2})([0-9]{2})([0-9]{2})(|/)([0-9]{3})(|[0-9])$"));

	withSlash = withSlash ? true : false;

	if (!match || withSlash !== (match[4] == "/")) // check personal number
		return false;

	if (match && regexOnly)
		return true;

	check = match[6];

	// check date
	year = parseInt(match[1], 10);
	month = parseInt(match[2], 10);
	if (month >= 50)
		month -= 50;
	day = parseInt(match[3], 10);
	if (day > 50)
		day -= 50;

	if (day > 0) {
		year += 1900;
		valid = isValidDate(year, month, day);
		year += 100;
		fullYear = (new Date()).getFullYear();
		valid = valid || (year <= fullYear && isValidDate(year, month, day));
		if (!valid)
			return false;
	}

	// check ico
	if (check.length > 0) // if length > 10, check mod 11
		return (parseInt(match[1] + match[2] + match[3] + match[5], 10) % 11) % 10 == parseInt(check, 10);
	else
		return true;
}

function isValidIco(data, regexOnly) {
	var match, chksum, computed, ico;
	if (typeof(data) !== "string")
		throw "Bad data type";
	// match ico
	match = data.match(/^([0-9]{1,7})([0-9])$/);
	if (!match)
		return false;
	if (match && regexOnly)
		return true;

	ico = match[2];
	// chksum
	chksum = parseInt(ico, 10);
	computed = computeMod11WeightedSum(match[1], icoWeights);

	if (computed == 10 || computed == 0) {
		computed = 1;
	} else {
		if (computed == 1)
			computed = 0;
		else
			computed = 11 - computed;
	}
	return computed == chksum;
}

function isValidDic(data, regexOnly) {
	var dic, match, num;
	if (typeof(data) !== "string")
		throw "Bad data type";

	match = data.match(/^[Cc][zZ]([0-9]{8,10})$/);
	if (!match)
		return false;

	if (match && regexOnly)
		return true;

	dic = match[1];

	if (dic.length == 8)
		return isValidIco(dic);

	if (dic.length == 10)
		return isValidPersonalNumber(dic, false, false);

	num = parseInt(dic, 10);
	return (num >= 600100016 && num <= 699999999) ||
		(num >= 700100016 && num <= 799999999) ||
		isValidPersonalNumber(dic, false, false);
}


function addValidator(methodName, methodFunction) {
	var name = methodName;
	var func = methodFunction;

	$.validator.addMethod(name,
		function(value, element) {
			if (this.optional(element) || !value)
				return true;
			return func(value);
		});

	$.validator.unobtrusive.adapters.add(name,
		function(options) {
			options.rules[name] = true;
			options.messages[name] = options.message;
		});
}

function isValidGps(value) {
	var m = value.match(/^(.*) *[;,] *(.*)$/);
	if (m) {
		return isValidGpsPart(m[1], true) && isValidGpsPart(m[2], false);
	} else {
		return false;
	}
}

function isValidGpsPart(value, lattitude) {
	var s = value.toString();
	var reg = new RegExp(
		"^([0-9]|[1-9][0-9]|1[0-8][1-9])°([0-9]|[0-9][0-9])'([0-9]|[0-9][0-9])(|\.([0-9]{1,5}))(''|\")([nNeEwWsS])$")
	var match = s.match(reg);

	if (match) {
		var type = match[7].toUpperCase();
		var degrees = parseInt(match[1], 10);
		var minutes = parseInt(match[2], 10);
		var seconds = parseInt(match[3], 10);
		var frac = 0;

		if (match[5]) {
			frac = parseFloat("0." + match[5]);
		}
		seconds += frac;

		if (minutes >= 60 || seconds >= 60)
			return false;

		seconds = degrees * 3600 + minutes * 60 + seconds;
		if (lattitude) {
			return (type == "N" || type == "S") && seconds <= 90 * 36000;
		} else {
			return (type == "E" || type == "W") && seconds <= 180 * 36000;
		}
	} else {
		return false;
	}

}

$.validator.addMethod("number",
	function(value, element) {
		return this.optional(element) || /^(|-)[0-9]+(|,[0-9]+)$/.test(value);
	});
$.validator.addMethod("range",
	function(value, element, param) {
		if (this.optional(element))
			return true;
		var val = parseFloat(value.replace(",", "."));
		return val >= param[0] && val <= param[1];
	});

addValidator("identificationnumber", function(value) { return isValidIco(value, false); });
addValidator("taxidentificationnumber", function(value) { return isValidDic(value, false); });
addValidator("personalnumber", function(value) { return isValidPersonalNumber(value, true, false); });
addValidator("accountnumber", function(value) { return isValidAccountNumber(value, false); });
addValidator("integer", function(value) { return /^(\+|\-|)\d+$/.test(value); });
addValidator("timespan",
	function(value) { return /^(|[1-9][0-9]*d)([01][0-9]|20|21|22|23):[0-5][0-9]:[0-5][0-9]$/.test(value); });
addValidator("time", function(value) { return /^([01][0-9]|20|21|22|23):[0-5][0-9]:[0-5][0-9]$/.test(value); });
addValidator("bytearray", function(value) { return /^([0-9a-fA-F][0-9a-fA-F])+$/.test(value); });
addValidator("passwordcharactercategoryarray", function(value) { return /^([aAsS0#]+)(\|[aAsS0#]+)*$/.test(value); });
addValidator("gpspoint", function(value) { return isValidGps(value); });

addValidator("timespanarray",
	function(value) {
		var parts = value.replace("\r\n", "\n").replace("\r", "\n").split("\n");
		for (var i = 0; i < parts.length; i++) {
			if (!/^(|[1-9][0-9]*d)([01][0-9]|20|21|22|23):[0-5][0-9]:[0-5][0-9]$/.test(parts[i])) {
				return false;
			}
		}
		return true;
	});

$.validator.addMethod("date",
	function(value, element) {

		if (this.optional(element) || !value)
			return true;

		var parts = /^\s*([0-9]{1,2})\.([0-9]{1,2})\.([0-9]{4,4})\s*$/.exec(value);
		if (parts) {
			var day = parseInt(parts[1], 10);
			var month = parseInt(parts[2], 10) - 1;
			var year = parseInt(parts[3], 10);
			var date = new Date(year, month, day);
			//$.log.debug(date + " " + date.getFullYear() + "=" + year + " " + date.getMonth() + "=" + month + " " + date.getDate() + "=" + day);
			return date.getFullYear() == year && date.getMonth() == month && date.getDate() == day;
		} else {
			return false;
		}
	});

addValidator("datetime",
	function(value) {
		var parts = /^\s*([0-9]{1,2})\.([0-9]{1,2})\.([0-9]{4,4})\s+([0-9]{1,2}):([0-9]{1,2})(|:[0-9]{1,2})\s*$/.exec(value);
		if (parts) {
			var day = parseInt(parts[1], 10);
			var month = parseInt(parts[2], 10) - 1;
			var year = parseInt(parts[3], 10);
			var hour = parseInt(parts[4], 10);
			var minute = parseInt(parts[5], 10);
			var second = parts[6] ? parseInt(parts[6].substring(1), 10) : 0;
			var date = new Date(year, month, day, hour, minute, second);
			//$.log.debug(date + " " + date.getFullYear() + "=" + year + " " + date.getMonth() + "=" + month + " " + date.getDate() + "=" + day);
			return date.getFullYear() == year &&
				date.getMonth() == month &&
				date.getDate() == day &&
				date.getHours() == hour &&
				date.getMinutes() == minute &&
				date.getSeconds() == second;
		} else {
			return false;
		}
	});


$.validator.addMethod("requiredif",
	function(value, element, params) {
		var other = $(params["name"], element.form);
		var otherValue;
		if (other.is(":checkbox")) {
			otherValue = other.is(":checked") ? "True" : "False";
		} else if (other.is(":radio")) {
			otherValue = other.filter(":checked").val();
		} else {
			otherValue = other.val();
		}
		if (otherValue == params["value"])
			return value != '' && value != null;
		return true;
	});

$.validator.addMethod("requiredifnot",
	function(value, element, params) {
		var other = $(params["name"], element.form);
		var otherValue;
		if (other.is(":checkbox")) {
			otherValue = other.is(":checked") ? "True" : "False";
		} else if (other.is(":radio")) {
			otherValue = other.filter(":checked").val();
		} else {
			otherValue = other.val();
		}
		if (otherValue != params["value"])
			return value != '' && value != null;
		return true;
	});

$.validator.unobtrusive.adapters.add("requiredif",
	["name", "value"],
	function(options) {
		var prefix = getModelPrefix(options.element.name),
			other = options.params["name"],
			fullOtherName = appendModelPrefix(other, prefix);

		options.rules["requiredif"] = {
			"name": ":input[name='" + escapeAttributeValue(fullOtherName) + "']",
			"value": options.params["value"]
		};

		options.messages["requiredif"] = options.message;
	});

$.validator.unobtrusive.adapters.add("requiredifnot",
	["name", "value"],
	function(options) {
		var prefix = getModelPrefix(options.element.name),
			other = options.params["name"],
			fullOtherName = appendModelPrefix(other, prefix);

		options.rules["requiredifnot"] = {
			"name": ":input[name='" + escapeAttributeValue(fullOtherName) + "']",
			"value": options.params["value"]
		};

		options.messages["requiredifnot"] = options.message;
	});