﻿var $ = require("../lib/jquery");
require("../lib/jquery-ui");

$.widget("ui.computeprice",
	{
		options: {
			price: null,
			quantity: null,
			allowDecimalPrice: false,
			allowDecimalQuantity: false
		},

		_parse: function(value, allowDecimal) {
			var isNumber = (allowDecimal ? /^[0-9]+(|,[0-9]+)$/ : /^[0-9]+$/).test(value);
			return isNumber ? parseFloat(value.replace(",", ".")) : NaN;
		},

		_formatPrice: function(value) {
			return (value.toFixed(2) + "").replace(".", ",") + " Kč";
		},

		_isNumber: function(value) {
			return !isNaN(value) && isFinite(value);
		},

		_create: function() {
			var recompute = $.proxy(this.recompute, this);
			this.element.addClass("ui-computeprice");
			this._price = $(this.options.price);
			this._quantity = $(this.options.quantity);

			this._price.add(this._quantity)
				.on("change.computeprice keydown.computeprice keyup.computeprice keypress.computeprice", recompute);

			this.recompute();
		},

		recompute: function() {
			var price = this._price.val();
			var quantity = this._quantity.val();
			this.element.text(this._getResultText(price, quantity));
		},

		_getResultText: function(price, quantity) {
			if (price == "" || quantity == "") {
				return "";
			} else {
				var parsedPrice = this._parse(price, this.options.allowDecimalPrice);
				var parsedQuantity = this._parse(quantity, this.options.allowDecimalQuantity);
				if (!this._isNumber(parsedPrice))
					return "Hodnota " + price + " není číslo";
				if (!this._isNumber(parsedQuantity))
					return "Hodnota " + quantity + " není číslo";
				var finalPrice = parsedPrice * parsedQuantity;

				if (this._isNumber(finalPrice)) {
					this.element.text(this._formatPrice(finalPrice));
				} else {
					this.element.text("Hodnota není číslo");
				}
			}
		},

		_destroy: function() {
			this.element.removeClass("ui-computeprice");
			this._price.add(this._quantity).unbind(".computeprice");
			this._price = null;
			this._quantity = null;
		}
	});